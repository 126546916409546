import {SIGNIN_USER,SIGNOUT_USER_SUCCESS,SIGNOUT_USER,GENERATE_ROUTE} from '../../app/constants/ActionTypes';
import {
    USER_DATA,
    USER_TOKEN_SET,
    INIT_URL
  } from "../../app/constants/ActionTypes";
const authenticateUser = (payload) => ({
    type:SIGNIN_USER,
    payload:payload
});

const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url
  };
};


const user_token_set = (payload) => {
    return { type: USER_TOKEN_SET, payload: payload }
  };
const user_data = (payload) => {
    return { type: USER_DATA, payload: payload }
  };

  const signout_user = () => {
    return { type: SIGNOUT_USER }
  };

const signout_user_success = () => {
    return { type: SIGNOUT_USER_SUCCESS }
  };
 
  const generate_route = (payload) => {
      return {type:GENERATE_ROUTE,payload:payload}
    } 

export {
  authenticateUser,
  user_token_set,
  user_data,
  signout_user_success,
  signout_user,
  setInitUrl,
  generate_route
}; 