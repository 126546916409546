import {FETCH_ERROR, FETCH_START, FETCH_SUCCESS, FETCH_COMMON_FILTER_OPTIONS_SUCCESS, FETCH_FILTER_OPTIONS_SUCCESS, FETCH_FUNCTION_FILTER_OPTIONS_SUCCESS, FETCH_LEVEL_FILTER_OPTIONS_SUCCESS,FETCH_PREFERENCES_FILTER_OPTIONS_SUCCESS, HIDE_MESSAGE, SHOW_MESSAGE} from "../../app/constants/ActionTypes";

export const fetchStart = () => {
  return {
    type: FETCH_START
  }
};

export const fetchSuccess = (data) => {
  return {
    type: FETCH_SUCCESS,
    payload: data
  }
};
export const fetchCommonFilterOptionsSuccess = (data) => {
  return {
    type: FETCH_COMMON_FILTER_OPTIONS_SUCCESS,
    payload: data
  }
};
export const fetchFilterOptionsSuccess = (data) => {
  return {
    type: FETCH_FILTER_OPTIONS_SUCCESS,
    payload: data
  }
};
export const fetchFunctionFilterOptionsSuccess = (data) => {
  return {
    type: FETCH_FUNCTION_FILTER_OPTIONS_SUCCESS,
    payload: data
  }
};
export const fetchLevelFilterOptionsSuccess = (data) => {
  return {
    type: FETCH_LEVEL_FILTER_OPTIONS_SUCCESS,
    payload: data
  }
};

export const fetchgetpreferencesFilterSuccess = (data) => {

  return {
    type: FETCH_PREFERENCES_FILTER_OPTIONS_SUCCESS,
    payload: data
  }
};


export const fetchError = (error) => {
  return {
    type: FETCH_ERROR,
    payload: error
  }
};

export const showMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  }
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE
  }
};






