import React from 'react';
import Button from '@material-ui/core/Button';

var year = new Date().getFullYear();
const Footer = () => {
    return (
      <footer className="app-footer">
        
        <Button
          href="https://futurebridge.com/"
          target="_blank"
          size="small"
          color="primary"
          style={{textTransform:'none'}}
        >
          <span className="d-inline-block" style={{color:"#000"}}>&copy; {year} Cheers Interactive (India) Private Limited. 
          All rights reserved. FutureBridge ® is a registered trademark of Cheers Interactive (India) Private Limited.</span>
        </Button>
      </footer>
    );
  }
;

export default Footer;
