import { takeLatest, call, put, fork } from 'redux-saga/effects';
import { SIGNIN_USER, SIGNOUT_USER } from '../../../app/constants/ActionTypes';
import { fetchStart, fetchSuccess, fetchError } from '../../actions/Common';
import { user_token_set, user_data, signout_user_success, generate_route } from '../../actions/Auth.action'
import * as usersApi from '../api/UsersApi';
function* authenticateUser({ payload }) {
    try {
        const email = payload.email;
        const password = payload.password;
        const empcode = payload.empcode;
		var result ="";
		if(email && password){
            yield call(usersApi.authenticateApp, { email, password});
        } else if(empcode) {
            yield put(fetchStart());
            yield call(usersApi.authenticateApp, { empcode });
        } else {
            yield put(fetchError(result.data.error));
        }
        if((email && password) || empcode){
            yield put(user_token_set(localStorage.getItem("token")));
            yield put(generate_route(localStorage.getItem("module_access")));
            yield put(user_data(''));
            yield put(fetchSuccess());
        }
    } catch (e) {
        if(payload.empcode){
            yield put(fetchError("Invalid Employee Code!"));
        } else {  
            yield put(fetchError("Incorrect email address or password!"));
        }
        console.log("ERROR WHILE FETCHING CLIENT USER", e);
    }
}

function* userSignOut(){
    try{
        localStorage.clear();
        yield put(signout_user_success());
    } catch (e) {

    }
}

function* watchAuthenticateUser() {
    yield takeLatest(SIGNIN_USER, authenticateUser);
}

function* watchUserSignOut() {
    yield takeLatest(SIGNOUT_USER, userSignOut);
}

const authSagas = [
    fork(watchAuthenticateUser),
    fork(watchUserSignOut)
];

export default authSagas;