import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import UserInfo from '../../components/UserInfo';
import config from '../../../config';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import {
  BELOW_THE_HEADER,
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION,
} from '../../constants/ActionTypes';
import { switchLanguage, toggleCollapsedNav } from '../../../store/actions/Setting';
import { fetchCommonFilterOptionsSuccess, fetchFilterOptionsSuccess, fetchFunctionFilterOptionsSuccess, fetchLevelFilterOptionsSuccess,fetchgetpreferencesFilterSuccess } from '../../../store/actions/Common';
import { getFilterDDOptions, getCommonFilterDDOptions, getLevelList, getFunctionList,getpreferences } from '../../../store/middelware/api/UsersApi'

class Header extends React.Component {

  onToggleCollapsedNav = (e) => {
    const val = !this.props.navCollapsed;
    this.props.toggleCollapsedNav(val);
  };
  constructor() {
    super();
    this.state = {
      anchorEl: undefined,
      searchBox: false,
      searchText: '',
      mailNotification: false,
      userInfo: false,
      langSwitcher: false,
      appNotification: false,
      keyword: '',
      searchCategory: ['All', 'Primary Sector', 'Value Chain', 'Job Title', 'Function', 'Keyword']

    }
  }
  componentDidMount() {
    var url = this.props.location.pathname.split('/');
    if (url[url.length - 3] === 'advanced-search') {
      this.setState({ keyword: url[url.length - 1] });
      this.setState({ category: url[url.length - 2] });
    }
    getCommonFilterDDOptions().then(response => this.props.fetchCommonFilterOptionsSuccess(response));
    getFilterDDOptions(1).then(response => this.props.fetchFilterOptionsSuccess(response));
    getFilterDDOptions(2).then(response => this.props.fetchFilterOptionsSuccess(response));
    getFilterDDOptions(3).then(response => this.props.fetchFilterOptionsSuccess(response));
    getFilterDDOptions(4).then(response => this.props.fetchFilterOptionsSuccess(response));
    getFilterDDOptions(5).then(response => this.props.fetchFilterOptionsSuccess(response));
    getFilterDDOptions(6).then(response => this.props.fetchFilterOptionsSuccess(response));
    getFilterDDOptions(7).then(response => this.props.fetchFilterOptionsSuccess(response));
    getFilterDDOptions(8).then(response => this.props.fetchFilterOptionsSuccess(response));
    getFilterDDOptions(9).then(response => this.props.fetchFilterOptionsSuccess(response));
    getLevelList().then(response => this.props.fetchLevelFilterOptionsSuccess(response));
    getFunctionList().then(response => this.props.fetchFunctionFilterOptionsSuccess(response));
    getpreferences().then((response)=>{this.props.fetchgetpreferencesFilterSuccess(response)})

  }
  render() {
    const { drawerType, navigationStyle, horizontalNavPosition } = this.props;
    const drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'd-block d-xl-none' : drawerType.includes(COLLAPSED_DRAWER) ? 'd-block' : 'd-none';
    // Hide Advance search option based on access modules 
    const accessModules = JSON.parse(localStorage.getItem('module_access'));
    const isAdvanceSearch = accessModules.length === 0 || (accessModules.some((module) => { return module.module_name === 'Advance Search'}));

    return (
      <AppBar
        className={`app-main-header ${(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === BELOW_THE_HEADER) ? 'app-main-header-top' : ''}`}>
        <Toolbar className="app-toolbar" disableGutters={false}>
          {navigationStyle === HORIZONTAL_NAVIGATION ?
            <div className="d-block d-md-none pointer mr-3" onClick={this.onToggleCollapsedNav}>
              <span className="jr-menu-icon">
                <span className="menu-icon" />
              </span>
            </div>
            :
            <IconButton className={`jr-menu-icon mr-3 ${drawerStyle}`} aria-label="Menu"
              onClick={this.onToggleCollapsedNav}>
              <span className="menu-icon" />
            </IconButton>
          }

          <Link className="app-logo mr-2 d-none d-lg-block" to="/" style={{ width: "50%" }}>
            <img src={require("../../../assets/images/logo.png")} alt="FutureBridge" title="FutureBridge" />
            <h3 className="app-logo-text">&nbsp;&nbsp;&nbsp; | &nbsp;&nbsp;&nbsp;Marketing Database</h3>
          </Link>
  
            <div className="app-userinfo-header">
              <div style={{ float: "right" }}><UserInfo /></div>
              {/* Hide Advance search option if advance search is disabled for user  */}
              { isAdvanceSearch && 
              <div id="form-wrapper">
              <button className="go-button" onClick={(ev) => {
                              if (this.state.keyword !== '') {
                                var searchCategory=document.getElementById("searchdropdown").value?document.getElementById("searchdropdown").value:"All"
                                window.location.replace(config.baseUrl + "/app/advanced-search/"+searchCategory+"/"+this.state.keyword);
                              }
                          }}><FontAwesomeIcon icon={faSearch} size="lg" style={{ cursor: "pointer",color: "#ffffff"}} /></button>
            <span className="nav-list">
              <span className="current-selection">
              </span>
              <select id="searchdropdown" value={this.state.category}
              onChange={(event) => this.setState({ category: event.target.category })}
              >
              {this.state.searchCategory.map(cat => (
                 <option key={cat} value={cat} >
                   {cat}
                  </option>
              ))}
              </select>

            </span>
            <div className="in-wrap">
              <input type="text" name="query" value={this.state.keyword} id="search-box"  onChange={(event) => this.setState({ keyword: event.target.value })}
                        onKeyPress={(ev) => {
                          if (ev.key === 'Enter') {
                            if (this.state.keyword !== '') {
                              var searchCategory=document.getElementById("searchdropdown").value?document.getElementById("searchdropdown").value:"All"
                               window.location.replace(config.baseUrl + "/app/advanced-search/"+searchCategory+"/"+this.state.keyword);
                            }
                          }
                        }}
              />
            </div>
            </div>
              }
              {/* <div className="fb-search-category" >
                  <DropDownListComponent
                    id="searchCategory"
                    name="searchCategory"
                    dataSource={this.state.searchCategory}
                    cssClass="e-filled"
                    value={this.state.category}
                  />
                </div>
                <div>
                <input type="text" id="keyword-search" className="fb-keyword-search" placeholder="Search"
                  onChange={(event) => this.setState({ keyword: event.target.value })}
                  value={this.state.keyword}
                  onKeyPress={(ev) => {
                    if (ev.key === 'Enter') {
                      if (this.state.keyword !== '') {
                        var searchCategory=document.getElementById("searchCategory").value?document.getElementById("searchCategory").value:"All"
                         window.location.replace(config.baseUrl + "/app/advanced-search/"+searchCategory+"/"+this.state.keyword);
                        //window.location.replace(config.baseUrl + "/app/advanced-search/"+this.state.keyword);

                      }
                    }
                  }}
                />
                </div> */}

            </div>

        </Toolbar>
      </AppBar>
    );
  }

}


const mapStateToProps = ({ settings}) => {
  const { drawerType, locale, navigationStyle, horizontalNavPosition} = settings;
  return { drawerType, locale, navigationStyle, horizontalNavPosition}
};

export default withRouter(connect(mapStateToProps, { 
  toggleCollapsedNav, 
  switchLanguage, 
  fetchCommonFilterOptionsSuccess,
  fetchFilterOptionsSuccess,
  fetchFunctionFilterOptionsSuccess,
  fetchLevelFilterOptionsSuccess,
  fetchgetpreferencesFilterSuccess
})(Header)); 